import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { SxTheme } from '@lib/theme/types';
import { Button } from '@ui/buttons/Button';

interface Props {
  onOpenBalanceModal: () => void;
  sx?: SxTheme;
  buttonsVariant?: 'text' | 'outlined' | 'contained';
}

export const AddonListItemActions: FC<Props> = ({
  onOpenBalanceModal,
  sx,
  buttonsVariant = 'text',
}) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onOpenBalanceModal}
      variant={buttonsVariant}
      color="dark"
      sx={{
        fontWeight: 400,
        fontSize: '16px',
        textTransform: 'none',
        ...sx,
      }}
    >
      {t('home:auth.addons.buttons.viewBalance')}
    </Button>
  );
};
