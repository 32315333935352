import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import {
  SubscriptionProductStatus,
  SubscriptionStatus,
} from '@api/ocb-digital/subscription/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, ListItemText, Typography } from '@mui/material';

interface Props {
  label: string;
  parentStatus: SubscriptionProductStatus;
  status: SubscriptionStatus;
}

export const SubscriptionOption: FC<Props> = ({
  label,
  parentStatus,
  status,
}) => {
  const { t } = useTranslation();

  if (!(parentStatus === 'PENDING' || status === 'PENDING')) {
    return <>{label}</>;
  }

  return (
    <Box sx={styles.getValue('root')}>
      <ListItemText>{label}</ListItemText>
      <Typography
        variant="body2"
        color="warning.light"
        ml={1}
        sx={styles.getValue('status')}
      >
        {t('common:pending')}
      </Typography>
    </Box>
  );
};

const styles = new SxStyles({
  status: {
    textTransform: 'uppercase',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
});
