import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';
import { NoDataPlaceholder } from '@ui/placeholders/NoDataPlaceholder';

import { OcbSubscriptionCard } from '../common/OcbSubscriptionCard';
import { isProductNotExpired } from '../../../common/helpers';

export const SubscriptionPackageContent: FC = () => {
  const { t } = useTranslation();
  const {
    selectedSubscription,
    isLoading: isSubscriptionsLoading,
    isFetching: isSubscriptionsFetching,
    isError: isSubscriptionsError,
  } = useSubscriptions();

  const subscriptionPackage =
    selectedSubscription?.status === 'ACTIVE'
      ? selectedSubscription?.products.find(
          (product) =>
            isProductNotExpired(product) && product.status === 'ACTIVE',
        )
      : undefined;

  if (isSubscriptionsFetching || isSubscriptionsLoading) {
    return <CenteredLoader />;
  }

  if (isSubscriptionsError) {
    return <ErrorPlaceholder />;
  }

  if (subscriptionPackage) {
    return (
      <OcbSubscriptionCard product={subscriptionPackage} isBasePackage={true} />
    );
  }

  if (!subscriptionPackage) {
    return <NoDataPlaceholder title={t('common:errors.noActivePackages')} />;
  }

  return null;
};
