import { isPast, parseISO } from 'date-fns';

import {
  SubscriptionProduct,
  SubscriptionProductChild,
} from '@api/ocb-digital/subscription/types';

export const isProductNotExpired = (
  product: SubscriptionProductChild,
): boolean =>
  new Date(product.validFrom) <= new Date() &&
  (product?.validTill ? new Date(product.validTill) > new Date() : true);

export const filterExpired = ({
  validTill = '',
}: {
  validTill?: SubscriptionProduct['validTill'];
}) => {
  const parsedDate = parseISO(validTill);
  if (!parsedDate) return true;
  return !isPast(parsedDate);
};
