import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { DefaultCard } from '@ui/cards/default-card/DefaultCard';

import { SubscriptionPackageContent } from './SubscriptionPackageContent';

export const SubscriptionPackage: FC = () => {
  const { t } = useTranslation();

  return (
    <DefaultCard title={t('home:auth.package.title')} noBorder={false}>
      <SubscriptionPackageContent />
    </DefaultCard>
  );
};
