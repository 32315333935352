import { FC } from 'react';

import { useRequestBalance } from '@lib/balance/useRequestBalance';
import { CACHE_KEYS } from '@lib/fetch/constants';
import { Stack } from '@mui/material';
import { CenteredLoader } from '@ui/loaders/CenteredLoader';

import { AmountField } from './AmountField';
import { ErrorPlaceholder } from '@ui/placeholders/ErrorPlaceholder';

interface Props {
  productId: string;
}

export const AddonBalance: FC<Props> = ({ productId }) => {
  const { data, isLoading, isFetching, isError } = useRequestBalance(
    CACHE_KEYS.addonsBalance,
  );
  const addonBalance = data?.balances?.find(
    (balance) => balance.balanceSource.productId === productId,
  );

  if (isLoading || isFetching) {
    return <CenteredLoader />;
  }

  if (isError || !addonBalance) {
    return <ErrorPlaceholder />;
  }

  return (
    <Stack spacing={1}>
      <AmountField
        translationKey="home:auth.addons.remainingBalance"
        price={addonBalance?.outstandingAmount}
        unitType={addonBalance?.unitType}
      />
      <AmountField
        translationKey="home:auth.addons.initialBalance"
        price={addonBalance?.initialAmount}
        unitType={addonBalance?.unitType}
      />
    </Stack>
  );
};
