import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { APP_ROUTES } from '@lib/route/constants';
import { useSubscriptions } from '@lib/subscriptions/SubscriptionsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { LinkButton } from '@ui/buttons/LinkButton';
import { DefaultCard } from '@ui/cards/default-card/DefaultCard';

import { isProductNotExpired } from '../../../common/helpers';
import { SubscriptionAddonsContent } from './SubscriptionAddonsContent';

export const SubscriptionAddons: FC = () => {
  const { t } = useTranslation();
  const { selectedSubscription } = useSubscriptions();
  const products =
    selectedSubscription?.status === 'ACTIVE'
      ? selectedSubscription?.products
          .find(
            (product) =>
              isProductNotExpired(product) && product.status === 'ACTIVE',
          )
          ?.children?.filter(
            (product) =>
              isProductNotExpired(product) && product.status === 'ACTIVE',
          )
      : undefined;

  return (
    <DefaultCard
      title={t('home:auth.addons.title')}
      sx={styles.getValue('cardContainer')}
      noBorder={false}
      contentSx={styles.getValue('cardContent')}
      button={
        <LinkButton
          href={APP_ROUTES.PROFILE.SPECIAL_OFFERS.path}
          variant="contained"
        >
          {t('home:auth.addons.buttons.addNew')}
        </LinkButton>
      }
    >
      <SubscriptionAddonsContent products={products} />
    </DefaultCard>
  );
};

const styles = new SxStyles({
  cardContainer: {
    maxHeight: {
      md: '600px',
    },
  },
  cardContent: {
    overflowY: {
      md: 'auto',
    },
  },
  action: {
    color: 'light.main',
  },
});
