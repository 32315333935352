import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from 'next-i18next';
import { is } from 'ramda';

import { SYMBOLS } from '@lib/placeholders/constants';
import { SxTheme } from '@lib/theme/types';
import { Stack } from '@mui/material';

interface Props {
  price?: number | null;
  currency?: string;
  sx?: SxTheme;
}

export const PriceFormat: FC<Props> = ({
  price,
  currency = SYMBOLS.emptyText,
  sx,
}) => {
  const value = is(Number, price) ? price : SYMBOLS.emptyText;
  const { t } = useTranslation();
  const currencySign = t(`common:currency.${currency}`, {
    defaultValue: '',
  });

  return (
    <Stack direction="row" spacing={2} sx={{ alignItems: 'baseline', ...sx }}>
      <NumericFormat
        value={value}
        displayType={'text'}
        thousandSeparator={true}
        decimalScale={2}
        renderText={(value) =>
          formatPriceWithCurrency({
            price: value,
            currency,
            currencySign: currencySign || '',
          })
        }
      />
    </Stack>
  );
};

function formatPriceWithCurrency({
  price,
  currency = '',
  currencySign = '',
}: {
  price?: string;
  currency?: string;
  currencySign?: string;
}) {
  if (!currencySign) return `${price} ${currency}`;
  return `${currencySign} ${price}`;
}
