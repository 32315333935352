import { FC } from 'react';

import { SubscriptionProductChild } from '@api/ocb-digital/subscription/types';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { Box, Stack, Typography } from '@mui/material';
import { OfferCardLine } from '@templates/common/offer-card/types';

export const AddonListItem: FC<{
  product: SubscriptionProductChild;
  lines: OfferCardLine[];
  actions?: React.ReactNode;
}> = ({ product, lines, actions }) => {
  const { tenant } = useTenantProps();
  const specificBackgroundColor =
    tenant.theme?.specifics?.authHomePageCard?.backgroundColor;

  return (
    <Box
      display="flex"
      alignItems="center"
      key={product.productId}
      sx={{
        borderRadius: '16px',
        border: '1px solid #E6E6E6',
      }}
    >
      <Box
        sx={{
          backgroundColor: specificBackgroundColor || '#E8F5FF',
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
          padding: '45px',
          width: '200px',
        }}
      >
        <Typography fontSize="18px" fontWeight={700}>
          {product.product.commercialName}
        </Typography>
      </Box>
      <Stack direction="row" spacing={10} ml={10}>
        {lines.map((line, index) => (
          <Stack key={index} direction="column" spacing={1}>
            <Typography fontSize="14px" color="text.secondary">
              {line.label}
            </Typography>
            <Typography fontSize="16px">{line.value}</Typography>
          </Stack>
        ))}
      </Stack>
      {actions}
    </Box>
  );
};
