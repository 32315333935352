import {
  InventoryItem,
  Subscription,
} from '@api/ocb-digital/subscription/types';
import { ContentModal } from '@ui/content-modal/ContentModal';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { api } from '@api/index';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { useApiQuery } from '@lib/fetch/useApiQuery';
import { toast } from 'react-toastify';
import { formatGenericError } from '@lib/fetch/errors';
import { Select } from '@ui/form/select/Select';
import { SelectOption } from '@lib/form/types';
import { FormProvider, useForm } from 'react-hook-form';
import { SimSwapFormValues, simSwapValidationSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useApiMutation } from '@lib/fetch/useApiMutation';

interface Props {
  isOpen: boolean;
  onClose(): void;
  subscription: Subscription;
  inventoryTypeId?: string;
}

export const SimSwapModal: FC<Props> = ({
  isOpen,
  onClose,
  subscription,
  inventoryTypeId,
}) => {
  const { t } = useTranslation('home');
  const { tenant } = useTenantProps();

  const { data: inventory, isLoading } = useApiQuery(getOrderInventory, {
    queryKey: [inventoryTypeId],
    enabled: !!inventoryTypeId,
  });

  const submitSimSwapCb = useApiMutation(submitSimSwap);

  const formMethods = useForm<SimSwapFormValues>({
    resolver: yupResolver(simSwapValidationSchema),
    defaultValues: simSwapValidationSchema.getDefault(),
    mode: 'onSubmit',
  });
  const { control, handleSubmit, reset } = formMethods;

  const reasonOptions: SelectOption[] = [
    {
      id: 'FAULTY_SIM_CARD',
      label: t('auth.resources.swapSimModal.form.reasonOptions.faultySimCard'),
    },
    {
      id: 'LOST_SIM_CARD',
      label: t('auth.resources.swapSimModal.form.reasonOptions.lostSimCard'),
    },
  ];

  const simTypeOptions: SelectOption[] =
    inventory?.variants.map((variant) => ({
      id: variant.id,
      label: variant.name,
    })) ?? [];

  return (
    <ContentModal
      keepMounted={false}
      titleLabel={t('auth.resources.swapSimModal.title')}
      isOpen={isOpen}
      onCancel={onCloseModal}
      showCloseModalIcon
      isLoading={isLoading || submitSimSwapCb.isPending}
      submitLabel={t('auth.resources.swapSimModal.form.swapButton')}
      onSubmit={handleSubmit(onSubmitSimSwap)}
    >
      <FormProvider {...formMethods}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Select
            name="simType"
            options={simTypeOptions}
            label={t('auth.resources.swapSimModal.form.simType')}
            control={control}
          />
          <Select
            name="reason"
            options={reasonOptions}
            label={t('auth.resources.swapSimModal.form.reason')}
            control={control}
          />
        </Box>
      </FormProvider>
    </ContentModal>
  );

  async function submitSimSwap() {
    try {
      if (!inventoryTypeId) {
        throw new Error(t('auth.resources.errors.inventoryTypeId'));
      }
      const simType = formMethods.getValues('simType');
      const reason = formMethods.getValues('reason');
      const oldInventoryItem: InventoryItem =
        subscription.inventory?.find(
          (inventory) => inventory.inventoryType.id === inventoryTypeId,
        ) ?? ({} as InventoryItem);

      if (
        !oldInventoryItem ||
        !oldInventoryItem?.orderProductId ||
        !oldInventoryItem.serial ||
        !oldInventoryItem.inventoryId ||
        !inventory?.id
      ) {
        throw new Error(t('auth.resources.errors.itemNotFound'));
      }
      const params = {
        subscriptionId: subscription.id,
        identifier: subscription.identifier,
        orderProductId: oldInventoryItem.orderProductId,
        reason,
        to: {
          inventoryTypeId: inventory?.id,
          inventoryTypeVariantId: simType,
        },
        from: {
          inventoryTypeId: oldInventoryItem.inventoryType.id,
          item: {
            inventoryId: oldInventoryItem?.inventoryId,
            serial: oldInventoryItem.serial,
          },
        },
      };
      await api.ocbDigital.order.changeInventoryOrder(tenant, params);
      toast.success(t('auth.resources.swapSimModal.form.success'));
      onCloseModal();
    } catch (error) {
      toast.error(formatGenericError(error));
    }
  }

  async function onSubmitSimSwap() {
    await submitSimSwapCb.mutateAsync();
  }

  function onCloseModal() {
    onClose();
    reset();
  }

  async function getOrderInventory() {
    try {
      if (!inventoryTypeId) {
        throw new Error(t('auth.resources.errors.inventoryTypeId'));
      }
      return api.ocbDigital.inventory.getOrderInventoryType(
        { inventoryTypeId },
        tenant,
      );
    } catch (error) {
      toast.error(formatGenericError(error));
      throw error;
    }
  }
};
