import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { PriceSchedule, ProductFee } from '@lib/offers/types';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { PriceFormat } from './PriceFormat';
import { SxStyles } from '@lib/theme/sxTheme';

interface Props {
  priceSchedule: PriceSchedule[];
  recurringFee?: ProductFee;
  display?: 'productCard' | 'orderSummary';
}

export const ScheduledPrice: FC<Props> = ({
  priceSchedule,
  recurringFee,
  display = 'productCard',
}) => {
  const { t } = useTranslation();
  const dynamicStyles = styles({ display });

  return (
    <Stack>
      {getScheduledPrices(priceSchedule)?.map((scheduledPrice, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: 1,
            justifyContent: 'inherit',
          }}
        >
          <PriceFormat
            price={scheduledPrice.price.price}
            currency={scheduledPrice.price.currency}
            sx={dynamicStyles.getValue('pricingFont')}
          />
          <Typography
            sx={dynamicStyles.getValue('pricingFont')}
          >{`(${scheduledPrice.startMonth}-${scheduledPrice.endMonth} ${t('common:prepaidPackages.month')})`}</Typography>
        </Box>
      ))}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          gap: 1,
        }}
      >
        <PriceFormat
          price={recurringFee?.price}
          currency={recurringFee?.currency}
          sx={dynamicStyles.getValue('pricingFont')}
        />
        <Typography sx={dynamicStyles.getValue('pricingFont')}>
          {t('common:prices.forRestOfPeriod')}
        </Typography>
      </Box>
    </Stack>
  );

  function getScheduledPrices(scheduledPrices: PriceSchedule[]) {
    return scheduledPrices.map((schedule, index) => {
      const startMonth =
        index === 0 ? 1 : scheduledPrices[index - 1].duration + 1;
      const endMonth = startMonth + schedule.duration;

      return { price: schedule.price, startMonth, endMonth };
    });
  }
};

const styles = ({ display }: { display?: 'productCard' | 'orderSummary' }) =>
  new SxStyles({
    pricingFont: { ...(display === 'productCard' && { fontWeight: 'bold' }) },
  });
