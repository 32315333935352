import { SxStyles } from '@lib/theme/sxTheme';
import { PriceFormat } from './PriceFormat';
import { StyleProps } from './types';
import { ProductPricing } from '@lib/offers/types';
import { ScheduledPrice } from './ScheduledPrice';

interface Props {
  pricing?: ProductPricing;
  fontSize?: number | string;
  display?: 'productCard' | 'orderSummary';
}

export const RecurringFee: React.FC<Props> = ({
  pricing,
  fontSize = '24px',
  display = 'productCard',
}) => {
  const dynamicStyles = styles({ fontSize });
  const priceFormatStyles =
    display === 'productCard'
      ? dynamicStyles.getValue('finalPrice')
      : undefined;

  if (!pricing?.priceSchedule?.length) {
    return (
      <PriceFormat
        price={pricing?.recurringFee?.price}
        currency={pricing?.recurringFee?.currency}
        sx={priceFormatStyles}
      />
    );
  }

  return (
    <ScheduledPrice
      priceSchedule={pricing?.priceSchedule}
      recurringFee={pricing?.recurringFee}
      display={display}
    />
  );
};

const styles = ({ fontSize }: StyleProps) =>
  new SxStyles({
    finalPrice: {
      fontWeight: 'fontWeightBold',
      lineHeight: 'initial',
      fontSize,
    },
  });
